import { typography } from "@/theme/typography"
import { isExtraLarge, isLarge } from "@/util/isLarge"
import { FlatList, View } from "react-native"
import TextWhite from "../TextWhite"
import PackageCard from "./PackageCard"
import { useTranslation } from "react-i18next"

import {
    REACT_APP_BASIC_ID,
    REACT_APP_ALLSPORT_ID,
    REACT_APP_PLATINUM_ID,
    REACT_APP_VIP_ID,
} from "@env"

type Package = {

    created_at?: string
    descripion?: string
    product_id: number
    is_default?: number
    name: string
    price_month: number
    price_year: number
    points_month: number
    points_year: number
    status?: {
        label: string
        value: string
    }
    stripe_product?: string
    updated_at?: string

    color?: boolean
    enableCategoriesSelection?: boolean
}

type PackagesProp = {
    packages: Package[]
}

const keyExtractor = (item: { key: any }) => item.key;


const PACKAGES: Package[] = [
    {
        name: 'Basic',
        product_id: REACT_APP_BASIC_ID,
        price_month: 69,
        price_year: 690,
        points_month: 69,
        points_year: 690,
        color: false,
        descripion:
            `<div>At our platform, we base our selections on value, ensuring that the probability of winning the bet exceeds the odds offered by the bookmaker.

            For instance:
        
            **Example:** Coin flip
            
        - Real odds: 50% tails, 50% heads
        - Bookmaker odds: 55% tails, 45% heads
        - Valuable pick: Tails (with a 5% advantage over the bookmaker)
            
            Join us to receive expert insights and valuable picks that give you the edge in your betting endeavors.
            </div>`,
        enableCategoriesSelection: true,

    },
    {
        name: 'ALL SPORT',
        product_id: REACT_APP_ALLSPORT_ID,
        price_month: 139,
        price_year: 1390,
        points_month: 139,
        points_year: 1390,
        descripion: '<div>Gain access to all our valuable picks across all sports categories at a better price with our subscription service!</div>',
        color: true
    },
    {
        name: 'Platinum Picks',
        product_id: REACT_APP_PLATINUM_ID,
        price_month: 249,
        price_year: 2490,
        points_month: 249,
        points_year: 2490,
        descripion: `<div>With our Platinum subscription, you'll receive our top-tier picks, carefully curated as the most valuable selections of the day. Similar to our basic picks, you'll enjoy an advantage over the bookmaker, but with enhanced odds and a higher winning percentage.
        </div>`,
        color: false
    },
    {
        name: 'VIP Access',
        product_id: REACT_APP_VIP_ID,
        price_month: 999,
        price_year: 9990,
        points_month: 999,
        points_year: 9990,
        descripion: `<div>Welcome to VIP Access, where we take your betting experience to the next level. Gain exclusive access to games for which our team possesses special insider information, providing insights into how each game will unfold.

How do we do it?
            
In the world of sports, outcomes aren't solely dictated by team strength; various factors can influence results, especially in lower divisions. That's where we step in, delivering precise information at crucial moments, ensuring you're always ahead of the game.
            
With a winning percentage approaching 100%, VIP Access ensures unparalleled success in your betting endeavors. </div> ` ,color: false
    }
]

const VIP = {
    name: 'VIP Access',
    product_id: REACT_APP_VIP_ID,
    price_month: 999,
    price_year: 9990,
    points_month: 999,
    points_year: 9990,
    descripion: `<div>Welcome to VIP Access, where we take your betting experience to the next level.Gain exclusive access to games for which our team possesses special insider information, providing insights into how each game will unfold.

How do we do it ?

In the world of sports, outcomes aren't solely dictated by team strength; various factors can influence results, especially in lower divisions. That's where we step in, delivering precise information at crucial moments, ensuring you're always ahead of the game.
    
With a winning percentage approaching 100%, VIP Access ensures unparalleled success in your betting endeavors. </div> `,
    color: false
}


const Packages: React.FC<PackagesProp> = ({ packages }) => {

    const { t } = useTranslation()

    return (
        <View style={[isLarge && { justifyContent: "center", alignItems: 'center' }, { paddingHorizontal: 10 }]}>

            <TextWhite style={{ fontSize: typography.h3, fontWeight: 'bold', marginBottom: 30 }}>{t("packages.title")}</TextWhite>

            {isLarge ?
                <>
                    <View style={{ flexDirection: 'row', gap: 10, flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                        {PACKAGES?.slice(0, -1).map((item: Package, index) => (
                            <PackageCard
                                length={packages.length}
                                indexMap={index}
                                key={item.product_id}
                                id={item.product_id}
                                name={item.name}
                                description={item.descripion}
                                price_month={item.price_month}
                                price_year={item.price_year}
                                points_month={item.points_month}
                                points_year={item.points_year}
                                color={item.color}
                                enableCategoriesSelection={item.enableCategoriesSelection}
                            />
                        ))}
                        {!isExtraLarge && <PackageCard
                            length={4}
                            indexMap={4}
                            key={VIP.product_id}
                            id={VIP.product_id}
                            name={VIP.name}
                            description={VIP.descripion}
                            price_month={VIP.price_month}
                            price_year={VIP.price_year}
                            points_month={VIP.points_month}
                            points_year={VIP.points_year}
                            color={VIP.color}

                        />}
                    </View>
                    {isExtraLarge && <View style={{ marginTop: 10 }}>
                        <PackageCard
                            length={4}
                            indexMap={4}
                            key={VIP.product_id}
                            id={VIP.product_id}
                            name={VIP.name}
                            description={VIP.descripion}
                            price_month={VIP.price_month}
                            price_year={VIP.price_year}
                            points_month={VIP.points_month}
                            points_year={VIP.points_year}
                            color={VIP.color}
                        />
                    </View>}
                </>
                :

                <FlatList
                    horizontal
                    keyExtractor={keyExtractor}
                    initialScrollIndex={1}
                    showsHorizontalScrollIndicator={true}
                    indicatorStyle='white'
                    data={PACKAGES}
                    snapToAlignment='center'
                    ItemSeparatorComponent={() => <View style={{ width: 20 }}></View>}
                    renderItem={({ item, index }: { item: Package, index: number }) =>
                        <PackageCard
                            length={packages.length}
                            indexMap={index}
                            key={item.product_id}
                            id={item.product_id}
                            name={item.name}
                            description={item.descripion}
                            price_month={item.price_month}
                            price_year={item.price_year}
                            points_month={item.points_month}
                            points_year={item.points_year}
                            color={item.color}
                            enableCategoriesSelection={item.enableCategoriesSelection}
                        />}
                />
            }
        </View>

    )
}


export default Packages