import { Button, Image } from "@rneui/themed"
import { View, Text, StyleSheet } from "react-native"
import { Icons } from "@/theme/theme"
import { colors } from "@/theme/colors"
import { typography } from "@/theme/typography"
import { isLarge } from "@/util/isLarge"
import { ProductType } from "@/theme/types"
import { useDispatch } from "react-redux"
import { cartAction } from "@/store/cart-slice"
import { addNewItemToCart } from "@/store/cart-actions"
import api from "@/util/api"

import { useNavigation } from "@react-navigation/native"
import { useAuthContext } from "@/context/AuthProvider"
const image_width = isLarge ? 400 : 350

type ProductProps = {
    product: ProductType
}

const Product: React.FC<ProductProps> = ({ product }) => {

    const dispatch = useDispatch()
    const { user } = useAuthContext()
    const navigation = useNavigation()
    const addItem = () => {
        dispatch(addNewItemToCart(product.id, product.name, product.price, navigation))
        
    }

    return (
        <View style={styles.container}>
            <View style={styles.image_container}>
                <Image style={styles.image} source={{ uri: product.image }} />
                <Text style={styles.points}>{product.points}pts</Text>
            </View>
            {/* <Text style={styles.brand}>APPLE</Text> */}
            <Text style={styles.title}>{product.name}</Text>
            <Button
                disabled={user?.points < product.points}
                onPress={addItem}
                buttonStyle={styles.button}
                titleStyle={styles.button_title}
                title={`Εξαργύρωση ${product.points} πόντων`}
            />
        </View>
    )

}

const styles = StyleSheet.create({
    container: {
        width: image_width,
        gap: 5,
        marginBottom: 50
    },
    image: {
        width: image_width,
        height: image_width,
        borderRadius: 10,
        objectFit: 'cover'
    },
    image_container: {
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center'
    },
    points: {
        bottom: 25,
        color: colors.text,
        backgroundColor: colors.orange,
        width: 154,
        paddingVertical: 2,
        textAlign: 'center',
        fontSize: 31,
        borderRadius: 23,
        fontWeight: '700'
    },
    brand: {
        color: colors.text,
        fontWeight: '200',
        fontSize: typography.normal
    },
    title: {
        color: colors.text,
        fontWeight: '700',
        fontSize: typography.product_title
    },
    button: {
        backgroundColor: colors.orange,
        width: '100%',
        textAlign: 'center',
        paddingVertical: 10,
        borderRadius: 23,
        marginTop: 10
    },
    button_title: {
        color: colors.text,
        fontSize: typography.menu,
        fontWeight: '700',
    }
})

export default Product