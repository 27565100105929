import { useAppContext } from "@/context/AppContext"
import { useAuthContext } from "@/context/AuthProvider"
import { colors } from "@/theme/colors"
import { Icons } from "@/theme/theme"
import { CustomDrawerProps, Screen } from "@/theme/types"
import api from "@/util/api"
import { is2XXL, isExtraLarge, isLarge } from "@/util/isLarge"
import { isWeb } from "@/util/isWeb"
import { DrawerContentScrollView, DrawerItem } from "@react-navigation/drawer"
import { useFocusEffect, useNavigation } from "@react-navigation/native"
import * as SecureStore from "expo-secure-store"
import React, { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Dimensions, Image, ScrollView, TouchableOpacity, View, findNodeHandle } from "react-native"
import ButtonPressIcon from "./ButtonPressIcon"

const { width } = Dimensions.get('window')

function transformString(inputString: string) {

    if (inputString) {
        const transformedString = inputString.replace(/[\W_]/g, '').toLowerCase()
        return transformedString
    }

}

const additionalScreens = [
    {
        id: 'wheel',
        title: 'Wheel of Fortune',
        name: 'Wheel',
        url: '/Wheel'
    },
    {
        id: 'Buy with points',
        name: 'Buy with points',
        title: 'Buy with points',
        url: '/catalog'
    }
]

const CustomDrawer: React.FC<CustomDrawerProps> = (props) => {

    const { packagesRef, scrollRef, screens } = useAppContext()
    const { pageId, loggedIn, setLoggedIn } = useAuthContext()
    const [loading, setLoading] = useState<boolean>(false)
    const { t } = useTranslation()
    const logout = async () => {

        setLoading(true)

        let res = {}

        if (isWeb) {
            res = await api().post('/logout')

        } else {
            const token = await SecureStore.getItemAsync("token");

            res = await api({ token }).delete('auth/token')
        }

        if (res.status >= 200 && res.status <= 300) {
            setLoggedIn(false)
            setLoading(false)

            // navigation.reset({
            //     index: 0,
            //     routes: [{ name: 'Home' }],
            // })
            navigation.navigate({
                name: 'Home',
                merge: true,
            })

        }

    }

    const scrollTo = useCallback(() => {
        if (packagesRef.current) {
            packagesRef.current.measureLayout(
                findNodeHandle(scrollRef.current),
                (x: number, y: number) => {
                    scrollRef.current.scrollTo({ x: 0, y, animated: true });
                },
                () => console.log('Error measuring layout')
            );
        }
    }, [packagesRef, scrollRef]);

    const navigation = useNavigation()

    const [openIndices, setOpenIndices] = useState<number[]>([])

    const toggleMenu = (index: number) => {
        setOpenIndices((prevIndices) => {

            const newIndices = [...prevIndices]

            const currentIndex = newIndices.indexOf(index)

            if (currentIndex !== -1) {
                newIndices.splice(currentIndex, 1)
            } else {
                newIndices.push(index)
            }

            return newIndices
        })
    }

    const isMenuOpen = (index: number) => openIndices.includes(index)

    const renderScreen = (screen: Screen) => {

        const isExpaned = openIndices.includes(screen?.id)

        return (
            <ScrollView key={screen.id}>
                <View
                    style={{
                        backgroundColor: colors.inactiveBG, borderRadius: 15, marginTop: 10,
                    }}
                    key={screen?.id}
                >

                    <DrawerItem
                        key={screen?.id}
                        label={screen?.title}

                        icon={() =>
                            <View style={{ backgroundColor: colors.bg_main, borderRadius: 30, paddingHorizontal: 5, paddingVertical: 7 }}>
                                <Image style={{ width: 15, height: 10 }} source={Icons.logo_menu} />
                            </View>
                        }

                        focused={transformString(screen?.title) == transformString(pageId)}

                        activeTintColor={'white'}
                        inactiveTintColor={'white'}
                        activeBackgroundColor={colors.activeBG}
                        inactiveBackgroundColor={colors.inactiveBG}
                        allowFontScaling={true}
                        labelStyle={{ marginTop: 0 }}
                        style={{ borderRadius: 15, }}
                        onPress={loggedIn || screen?.url.replace("/", "") == 'free-picks'
                            ? () => navigation.navigate({
                                name: screen?.id === 'wheel' ? 'Wheel' : screen?.id === 'Buy with points' ? 'Catalog' : 'Bets',
                                params: screen?.id === 'wheel' || screen?.id === 'catalog' ? {} : { title: screen?.url.replace("/", "") },
                                merge: true,
                            })
                            : scrollTo}

                    />

                    {screen?.children?.length > 0 &&
                        <TouchableOpacity onPress={() => toggleMenu(screen?.id)} style={{ position: 'absolute', right: 25, top: 25 }}>
                            <Image style={{ width: 14, height: 8 }} source={Icons.expand} />
                        </TouchableOpacity>}

                    {isMenuOpen(screen?.id) && screen?.children && screen?.children?.length > 0 && (
                        <View style={{ marginTop: 0, paddingTop: 0, marginLeft: 5 }}>
                            {screen?.children.map((child) => (
                                <React.Fragment key={child.id}>
                                    {renderScreen(child)}
                                </React.Fragment>
                            ))}
                        </View>
                    )}
                </View>
            </ScrollView>
        )
    }

    return (
        screens && (
            <DrawerContentScrollView style={{ marginLeft: isExtraLarge ? 140 : 10, minWidth: is2XXL && width * 0.20}} {...props}>
                <View style={{ marginRight: 10  }}>
                    {screens.map((screen, index) => renderScreen(screen))}
                    {loggedIn && additionalScreens.map((screen, index) => renderScreen(screen))}

                    {!isLarge &&
                        <View style={{ alignItems: 'flex-end', marginTop: 20 }}>
                            <ButtonPressIcon
                                loading={loading}
                                // onPress={!loggedIn ? () => navigation.reset({
                                //     index: 0,
                                //     routes: [{ name: 'Login' }],
                                //     merge: true
                                // }) : logout}

                                onPress={!loggedIn ? () => navigation.navigate({
                                    name: 'Login',
                                    merge: true,
                                }) : logout}
                                title={loggedIn ? t("logout") : t("login")}
                                Icon={() => (<Image style={{ width: 13.92, height: 15.51 }} source={Icons.login} />)}
                            />
                        </View>
                    }
                </View>
            </DrawerContentScrollView>
        )

        // <DrawerItemList  {...props} />
    )
}



export default CustomDrawer