import { colors } from "@/theme/colors";
import { TooltipProps } from "@rneui/base";
import { Tooltip, Text, Image, Badge, Button } from "@rneui/themed";
import { useEffect, useMemo, useState } from "react";
import { ScrollView, View, StyleSheet, Dimensions } from "react-native";
import { Row, Table } from 'react-native-table-component';
import { isLarge } from "@/util/isLarge";
import { Icons } from "@/theme/theme";
import { BetType } from "@/theme/types";
import { isWeb } from "@/util/isWeb";
import { useNavigation } from "@react-navigation/native";
import ButtonPressIcon from "./ButtonPressIcon";
import { typography } from "@/theme/typography";
import StatusBadge from "./StatusBadge";

const { height, width } = Dimensions.get('window')

type TableDesktopProps = {
    
    bets: BetType[],
    upcomingBets: BetType[],
    passedBets: BetType[],
    state: {
        tableHead: string[]
        widthArr: string[]
    },
    tableData: BetType[]
    upcomingTableData: BetType[],
    passedTableData: BetType[],
    setTableData: React.Dispatch<React.SetStateAction<BetType[]>>,
    setUpcomingTableData: React.Dispatch<React.SetStateAction<BetType[]>>,
    setPassedTableData: React.Dispatch<React.SetStateAction<BetType[]>>,

}

export const ControlledTooltip: React.FC<TooltipProps> = (props) => {

    const [open, setOpen] = useState(false)

    return (
        <Tooltip
            visible={open}
            onOpen={() => {
                setOpen(true)
            }}
            onClose={() => {
                setOpen(false)
            }}
            {...props}
        />
    );
};

const TableDesktop: React.FC<TableDesktopProps> = ({
    bets,
    state,
    setTableData,
    tableData,
    upcomingBets,
    passedBets,
    setUpcomingTableData,
    setPassedTableData,
    upcomingTableData,
    passedTableData
}) => {

    const navigation = useNavigation()
    
    const tooltip = (bet: BetType) => {
        console.log(bet);

        if (bet?.description) {
            return (
                <ControlledTooltip
                    popover={<Text style={{ color: colors.text }}>{bet.description}</Text>}
                    width={300}
                    height={isLarge || isWeb ? 'auto' : 100}
                    backgroundColor={colors.orange}
                    overlayColor='transparent'
                    withPointer={false}
                >
                    <Image source={Icons.info} style={{ width: 20, height: 20, left: 10 }} />
                </ControlledTooltip>
            )
        } else {
            return null
        }
    }

    const reversedBets = useMemo(() => {
        return bets.toReversed()
    }, [bets])

    const reversedUpcomingBets = useMemo(() => {
        return upcomingBets.toReversed()
    }, [upcomingBets])

    const reversedPassedBets = useMemo(() => {
        return passedBets.toReversed()
    }, [passedBets])

    useEffect(() => {
        //console.log('Bets', bets)
        const tempData: any = []
        const tempDataUpcoming: any = []
        const tempDataPassed: any = []
        console.log(reversedBets);

        if (reversedBets) {
            reversedBets?.map((bet) => {

                let concatBet = ''
                let concatResult = ''
                let concatLeague = ''

                let betType = bet?.betType?.map((type: { value: string }) => {
                    return type?.value
                })
                if (betType) {
                    concatBet = betType?.join(' ')
                }

                let results = bet?.result?.map((type: { value: string }) => {
                    return type?.value
                })
                if (results) {
                    concatResult = results?.join(' ')
                }

                let league = bet?.league?.map((type: { value: string }) => {
                    return type?.value
                })
                if (league) {
                    concatLeague = league?.join(' ')
                }




                tempData.push([`${bet?.event_date} ${bet?.event_time}`, concatLeague, bet?.event,
                concatBet ? concatBet : <ButtonPressIcon center title={bet?.subscriptionType} onPress={() => navigation.navigate('Home')} />,
                    concatResult, bet?.odds, tooltip])
            })
        }

        if (reversedUpcomingBets) {
            reversedUpcomingBets?.map((bet) => {

                let concatBet = ''
                let concatResult = ''
                let concatLeague = ''

                let betType = bet?.betType?.map((type: { value: string }) => {
                    return type?.value
                })
                if (betType) {
                    concatBet = betType?.join(' ')
                }

                let results = bet?.result?.map((type: { value: string }) => {
                    return type?.value
                })
                if (results) {
                    concatResult = results?.join(' ')
                }

                let league = bet?.league?.map((type: { value: string }) => {
                    return type?.value
                })
                if (league) {
                    concatLeague = league?.join(' ')
                }
                tempDataUpcoming.push([`${bet?.event_date} ${bet?.event_time}`, concatLeague, bet?.event,
                concatBet ? concatBet : <ButtonPressIcon width={350} center title={bet?.subscriptionType} onPress={() => navigation.navigate('Home')} />,
                    concatResult, bet?.odds, tooltip(bet)])
            })
        }

        if (reversedPassedBets) {
            reversedPassedBets?.map((bet) => {

                let concatBet = ''
                let concatResult = ''
                let concatLeague = ''

                let betType = bet?.betType?.map((type: { value: string }) => {
                    return type?.value
                })
                if (betType) {
                    concatBet = betType?.join(' ')
                }

                let results = bet?.result?.map((type: { value: string }) => {
                    return type?.value
                })
                if (results) {
                    concatResult = results?.join(' ')
                }

                let league = bet?.league?.map((type: { value: string }) => {
                    return type?.value
                })
                if (league) {
                    concatLeague = league?.join(' ')
                }
                tempDataPassed.push([`${bet?.event_date} ${bet?.event_time}`, concatLeague, bet?.event,
                concatBet ? concatBet : <ButtonPressIcon width={350} center title={bet?.subscriptionType} onPress={() => navigation.navigate('Home')} />,
                    concatResult, bet?.odds, tooltip])
            })
        }

        setTableData(tempData)
        setUpcomingTableData(tempDataUpcoming)
        setPassedTableData(tempDataPassed)
    }, [reversedBets, reversedUpcomingBets, reversedPassedBets])

    return (
        <View style={styles.container}>
            <ScrollView contentContainerStyle={{ flexDirection: 'column' }} horizontal={true}>

                <View style={styles.titleContainer}>
                    <Image style={{ width: 26.41, height: 24 }} source={Icons.upcoming} />
                    <Text style={[styles.title, { bottom: 2 }]}>Upcoming</Text>
                    <Image style={{ width: 57.93, height: 21 }} source={Icons.bets} />
                </View>

                <View>
                    <Table borderStyle={{ borderWidth: 1, borderColor: '#ff00003b' }}>
                        <Row data={state.tableHead} widthArr={state.widthArr} style={styles.header} textStyle={styles.text} />
                    </Table>
                    <ScrollView style={styles.dataWrapper}>

                        <Table borderStyle={{ borderWidth: 1, borderColor: '#f15a2452' }}>
                            {
                                upcomingTableData?.map((rowData: BetType, index) => (

                                    <View>
                                        {reversedUpcomingBets[index]?.subscriptionType &&
                                            <Badge containerStyle={{ position: 'absolute', top: 2, left: 2 }} value={reversedUpcomingBets[index]?.subscriptionType}></Badge>}
                                        {reversedUpcomingBets[index]?.status != undefined &&
                                            <View style={{ position: 'absolute', bottom: 2, right: 2 }} >
                                                <StatusBadge status={reversedUpcomingBets[index]?.status} />
                                            </View>}
                                        <Row
                                            key={index}
                                            data={rowData}
                                            widthArr={state.widthArr}
                                            style={[styles.row, index % 2 && { backgroundColor: 'transparent' }]}
                                            textStyle={styles.text}

                                        />
                                    </View>
                                ))
                            }
                        </Table>
                    </ScrollView>
                </View>
                <View style={[styles.titleContainer, { marginTop: 50 }]}>
                    <Image style={{ width: 24, height: 24 }} source={Icons.clock} />
                    <Text style={[styles.title, { bottom: 1.5 }]}>Recent Bets</Text>
                    <Image style={{ width: 57.93, height: 21 }} source={Icons.bets} />
                </View>

                <View>
                    <Table borderStyle={{ borderWidth: 1, borderColor: '#ff00003b' }}>
                        <Row data={state.tableHead} widthArr={state.widthArr} style={styles.header} textStyle={styles.text} />
                    </Table>
                    <ScrollView style={styles.dataWrapper}>

                        <Table borderStyle={{ borderWidth: 1, borderColor: '#f15a2452' }}>
                            {
                                passedTableData?.map((rowData: BetType, index) => (

                                    <View>
                                        {reversedPassedBets[index]?.subscriptionType &&
                                            <Badge containerStyle={{ position: 'absolute', top: 2, left: 2 }} value={reversedPassedBets[index]?.subscriptionType}></Badge>}
                                        {reversedPassedBets[index]?.status != undefined &&
                                            <View style={{ position: 'absolute', bottom: 2, right: 2 }} >
                                                <StatusBadge status={reversedPassedBets[index]?.status} />
                                            </View>}
                                        <Row
                                            key={index}
                                            data={rowData}
                                            widthArr={state.widthArr}
                                            style={[styles.row, index % 2 && { backgroundColor: 'transparent' }]}
                                            textStyle={styles.text}

                                        />
                                    </View>
                                ))
                            }
                        </Table>
                    </ScrollView>
                </View>
            </ScrollView>
        </View>

    )
}

const styles = StyleSheet.create({
    container: { flex: 1, padding: 10, minHeight: height * 0.4 },
    header: { height: 50 },
    text: { textAlign: 'center', fontWeight: '300', color: colors.text, fontSize: isLarge ? 14 : 11 },
    dataWrapper: { marginTop: -1 },
    row: { height: 'auto', minHeight: 60, backgroundColor: '#89838342' },
    title: {
        color: colors.text,
        fontSize: 28,
        fontWeight: '500',

    },
    titleContainer: {
        flexDirection: 'row',
        gap: 15,
        alignItems: 'center',
        marginBottom: 8,
        paddingVertical: 14
    }
})

export default TableDesktop