import { colors } from "@/theme/colors";
import { Icons } from "@/theme/theme";
import { isLarge } from "@/util/isLarge";
import { Badge, Divider, Button } from "@rneui/themed";
import React, { Fragment, useEffect, useState } from "react";
import { Pressable, Text, View, Image, StyleSheet, Dimensions, ScrollView, ActivityIndicator } from "react-native";
import Modal from "react-native-modal";
import ScrollViewIndicator from 'react-native-scroll-indicator';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { uiActions } from '@/store/ui-slice';
import { Icon } from '@rneui/themed';
import TextWhite from './TextWhite';
import { CartItemType } from "@/store/cart-actions";
import { typography } from "@/theme/typography";
import { cartAction } from "@/store/cart-slice";
import { increaseItemQuantity } from "@/store/cart-actions";
import api from "@/util/api";
import { decreaseItemQuantity } from "@/store/cart-actions";
import { useNavigation } from "@react-navigation/native";
const { height } = Dimensions.get('window')

const CartModal = () => {
    const navigation = useNavigation();
    const dispatch = useDispatch()
    const showCard = useSelector(state => state.ui.cartIsVisible)
    const cartQuantity = useSelector(state => state.cart.totalQuantity)
    const cart: CartItemType[] = useSelector(state => state.cart.items)
    const cartTotal: string = useSelector(state => state.cart.totalPrice)
    const notification = useSelector(state => state.ui.notification)

    const toggleCartHandler = () => {
        dispatch(uiActions.toggle())
    }

    const handleCheckoutButton = () => {
        dispatch(uiActions.toggle())
        navigation.navigate('Checkout')
    }

    const addItem = (productIndex: number, id: number) => {
        dispatch(increaseItemQuantity(productIndex, id))
    }

    const removeItem = (productIndex: number, id: number) => {
        dispatch(decreaseItemQuantity(productIndex, id))

    }

    return (
        <View style={{ flex: 1 }}>

            <Pressable onPress={toggleCartHandler}>

                <Image style={{ width: 24, height: 20, marginTop: 2 }} source={Icons.basket} />
                <Badge
                    status='error'
                    value={cartQuantity}
                    containerStyle={{ position: 'absolute', top: -10, left: 10 }}
                    badgeStyle={{ borderWidth: 0 }}

                />
            </Pressable>

            <Modal
                style={styles.modal}
                animationIn='fadeInRightBig'
                animationOut='fadeOutRightBig'
                isVisible={showCard}
                onBackdropPress={() => dispatch(uiActions.toggle())}
                backdropOpacity={0.2}
            >
                <ScrollViewIndicator
                    shouldIndicatorHide={false}
                    flexibleIndicator={false}
                    scrollIndicatorStyle={{ backgroundColor: colors.orange }}
                    scrollIndicatorContainerStyle={{ backgroundColor: colors.bg_secondary }}
                    style={styles.container}
                >

                    <View style={styles.notifContainer}>
                        {cart && cart.map((item: CartItemType, ind: number) => (

                            < Fragment key={item.id} >
                                <View style={{ marginVertical: 15 }}>
                                    <Image style={{ width: 80, height: 80, borderRadius: 5 }} source={{ uri: item.image }} />
                                    <View style={styles.itemContainer}>
                                        <Text style={styles.text}>{item.name}</Text>
                                        <Text style={styles.italic}>{item.price}pts</Text>
                                        
                                    </View>
                                </View>
                                <Divider color={colors.orange} />
                            </Fragment>
                        ))}
                        <View style={styles.checkoutContainer}>
                            
                            <Button
                                onPress={handleCheckoutButton}
                                buttonStyle={{ borderColor: colors.orange, borderRadius: 8 }}
                                titleStyle={{ color: colors.orange }}
                                type='outline'
                                title='Checkout'
                            />
                        </View>
                    </View>

                </ScrollViewIndicator>

            </Modal>

        </View >
    )
}

const styles = StyleSheet.create({
    buttonsContainer: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    modal: {
        alignItems: 'flex-end',
        top: 40,
        elevation: 5,
        justifyContent: 'flex-start'
    },
    container: {
        flex: 1,
        width: isLarge ? 500 : '100%',
        maxHeight: height / 1.5
    },
    notifContainer: {
        backgroundColor: colors.bg_main,
        padding: 10,
        borderWidth: 1,
        borderColor: colors.orange,
        borderRadius: 10,

    },
    itemContainer: {
        gap: 10,
        marginTop: 5,
        padding: 10,
        paddingBottom: 0,
        borderRadius: 6,
    },
    checkoutContainer: {
        gap: 10,
        marginVertical: 15,
        padding: 10,
        borderRadius: 6,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    text: {
        color: colors.text,
        fontSize: 18
    },
    price: {
        color: colors.text,
        fontSize: typography.normal
    },
    italic: {
        color: '#76899d',
        fontStyle: 'italic',
        fontSize: 12
    }

})

export default CartModal;
