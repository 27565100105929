import React, { useEffect, useRef, useState } from "react";
import { Dimensions, ScrollView, StyleSheet, View } from "react-native";

import { useAppContext } from "@/context/AppContext";
import { useAuthContext } from "@/context/AuthProvider";
import { HomeScreenRouteProp } from "@/theme/types";
import { isExtraLarge, isLarge } from "@/util/isLarge";
import { useFocusEffect, useRoute } from "@react-navigation/native";

import CustomDrawer from "@/components/CustomDrawer";
import Footer from "@/components/Footer";
import HomeImage from "@/components/HomePage/HomeImage";
import Packages from "@/components/HomePage/Packages";
import SectionText from "@/components/HomePage/SectionText";
import SEO from '@/components/SEO';
import { isWeb } from "@/util/isWeb";

import video from '@/video/klokbet.mp4'

const { width } = Dimensions.get('window')

export default function Home() {

  const route = useRoute<HomeScreenRouteProp>()

  const { logOut, user, setPageId } = useAuthContext()
  const { homeData, screens, scrollRef, packagesRef, seoTitle, setSeoTitle, seoDescription, setSeoDescription } = useAppContext()


  const [drawerZIndex, setDrawerZIndex] = useState<number>(1)

  useFocusEffect(
    React.useCallback(() => {
      setPageId("home")
      setSeoTitle(homeData?.seo?.title)
      setSeoDescription(homeData?.seo?.description)

    }, [homeData])
  )

  const handleScroll = (event: { nativeEvent: { layoutMeasurement: { height: any; }; contentSize: { height: any; }; contentOffset: { y: any; }; }; }) => {


    const scrollViewHeight = event.nativeEvent.layoutMeasurement.height
    const contentHeight = event.nativeEvent.contentSize.height
    const scrollOffset = event.nativeEvent.contentOffset.y
    const customDrawerHeight = 400
    const bottomPosition = contentHeight - scrollViewHeight

    if (scrollOffset >= bottomPosition) {
      setDrawerZIndex(0)
    } else {
      setDrawerZIndex(1)
    }
  }  

  return (

    homeData && screens &&
    <>
      {seoTitle && isWeb && <SEO title={seoTitle} description={seoDescription} />}
      {isLarge && <View
        style={[styles.menu_container, { zIndex: drawerZIndex === 0 ? 0 : 1 }]}>
        <CustomDrawer />
      </View>}
      <ScrollView
        showsVerticalScrollIndicator={false}
        ref={scrollRef}
        onScroll={isLarge ? handleScroll : () => { }}
        scrollEventThrottle={1000}
      >
        <View
          style={{
            flex: 1,
            marginRight: isExtraLarge ? 140 : 0,
            flexDirection: 'row',
            justifyContent: 'flex-end'
          }}>

          {isLarge && <View style={{ flex: 3, zIndex: -1 }}>

          </View>}

          <View style={{ flex: 7 }}>
            <HomeImage
              //videoUrl={homeData?.video}
              videoUrl={video}
              carouselImages={homeData?.images?.carouselImages}
            />
            {/* <CategoryCarousel /> */}
            {/* <Banner /> */}
            <View ref={packagesRef}>
              <Packages packages={homeData?.subscriptions?.data} />
            </View>
            <SectionText />
          </View>
        </View>
        <Footer />
      </ScrollView>
    </>
  );
}

const styles = StyleSheet.create({
  menu_container: {
    flex: 1,
    minHeight: 400,
    width: width * 0.25,

    position: 'absolute'
  }
})
