import React, { useEffect, useState } from "react";
import { ActivityIndicator, Dimensions, ScrollView, View } from "react-native";
import { useAuthContext } from "@/context/AuthProvider";
import * as SecureStore from "expo-secure-store";

import BetCards from "@/components/BetCards";
import Footer from "@/components/Footer";
import TableDesktop from "@/components/Table";
import { BetType } from "@/theme/types";
import api from "@/util/api";
import { is2XXL, isLarge } from "@/util/isLarge";
import { isWeb } from "@/util/isWeb";
import CustomDrawer from "@/components/CustomDrawer";
import { useTranslation } from "react-i18next";
import SEO from '@/components/SEO';
import { useFocusEffect } from "@react-navigation/native";
import { useAppContext } from "@/context/AppContext";
import { useDispatch } from "react-redux";
import { uiActions } from "@/store/ui-slice";
import { colors } from "@/theme/colors";
import ScrollViewIndicator from "react-native-scroll-indicator"

const { height, width } = Dimensions.get('window')

const Bets = ({ route, navigation }) => {

  const { t } = useTranslation()
  const { user, logOut, setPageId } = useAuthContext()
  const title = route?.params?.title
  const [bets, setBets] = useState<BetType[]>([])
  const [upcomingBets, setUpcomingBets] = useState<BetType[]>([])
  const [passedBets, setPassedBets] = useState<BetType[]>([])
  const [tableData, setTableData] = useState<BetType[]>()
  const [upcomingTableData, setUpcomingTableData] = useState<BetType[]>()
  const [passedTableData, setPassedTableData] = useState<BetType[]>()

  const [category, setCategory] = useState(null)
  const [loading, setLoading] = useState<boolean>(true)
  const dispatch = useDispatch()
  const { seoTitle, setSeoTitle, seoDescription, setSeoDescription } = useAppContext()

  const state = navigation.getState()

  const state1 = {
    tableHead: [t('table.date'), t('table.league'), t('table.teams'), t('table.type'), t('table.result'), t('table.odd'), ""],
    widthArr: [180, 180, 180, 180, 180, 180, 40],
  }

  useFocusEffect(
    React.useCallback(() => {

      setPageId(title)
      setBets([])
      setUpcomingBets([])
      setPassedBets([])

      const fetchData = async () => {
        setLoading(true)
        try {

          if (isWeb) {
            const res = await api().get(`/categories/${title}`)

            if (res.status >= 200 && res.status <= 300) {


              setBets(res?.data?.data?.bets?.data)
              setSeoTitle(res?.data?.data?.category?.seo?.title)
              setSeoTitle(res?.data?.data?.category?.seo?.description)
              setCategory(res?.data?.data?.category)


            }
          } else {
            const token = await SecureStore.getItemAsync("token");
            const res = await api({ token }).get(`/categories/${title}`)
            //console.log('fetch response', res?.data?.data?.bets?.data)
            if (res.status >= 200 && res.status <= 300) {
              setBets(res?.data?.data?.bets?.data)
              setSeoTitle(res?.data?.category?.seo?.title)
              setSeoTitle(res?.data?.category?.seo?.description)
              setCategory(res?.data?.category)

            }
          }

        } catch (e) {

          dispatch(uiActions.showNotification({
            message: e?.errors,
            visible: true
          }))

        } finally {
          setLoading(false)
        }

      }

      fetchData()

    }, [title])
  )

  useFocusEffect(
    React.useCallback(() => {

      setSeoTitle(category?.seo?.title)
      setSeoDescription(category?.seo?.description)

    }, [category])
  )

  useEffect(() => {

    if (bets?.length > 0) {
      const currentDate = new Date()
      const currentTime = currentDate.getTime()

      const upcomingBets = bets.filter((event) => {
        const [day, month, year] = event.event_date.split("/")
        const [hours, minutes, seconds] = event.event_time.split(":")
        const eventDate = new Date(`${year}-${month}-${day}T${hours}:${minutes}:${seconds}`)
        return eventDate.getTime() > currentTime
      })

      const passedBets = bets.filter((event) => {
        const [day, month, year] = event.event_date.split("/")
        const [hours, minutes, seconds] = event.event_time.split(":")
        const eventDate = new Date(`${year}-${month}-${day}T${hours}:${minutes}:${seconds}`)
        return eventDate.getTime() <= currentTime
      })

      setUpcomingBets(upcomingBets)
      setPassedBets(passedBets)
    }

  }, [bets])

  return (
    <ScrollViewIndicator
      shouldIndicatorHide={false}
      flexibleIndicator={false}
      scrollIndicatorStyle={{ backgroundColor: colors.orange }}
      scrollIndicatorContainerStyle={{ backgroundColor: colors.bg_secondary }}
    >
      {isLarge
        ?
        <View style={{ flex: 1, flexDirection: 'row' , gap :is2XXL ?  100 : 50 }}>
          {seoTitle && isWeb && <SEO title={seoTitle} description={seoDescription} />}

          <View style={{ flex: 2.5 }}>
            <CustomDrawer />
          </View>
          <View style={{ flex: 7.5 }}>
            {!loading
              ? <TableDesktop
                bets={bets}
                state={state1}
                setTableData={setTableData}
                tableData={tableData}
                passedBets={passedBets}
                setPassedBets={setPassedBets}
                upcomingBets={upcomingBets}
                setUpcomingBets={setUpcomingBets}
                upcomingTableData={upcomingTableData}
                setUpcomingTableData={setUpcomingTableData}
                passedTableData={passedTableData}
                setPassedTableData={setPassedTableData}
              />
              : <View style={{ flex: 1, alignItems: "center", justifyContent: 'center', minHeight: height * 0.4 }}>
                <ActivityIndicator size={'large'} />
              </View>
            }
          </View>

        </View>

        : !loading && !isLarge
          ? <BetCards passedBets={passedBets} upcomingBets={upcomingBets} bets={bets} />
          : <View style={{ flex: 1, alignItems: "center", justifyContent: 'center', minHeight: height * 0.4 }}>
            <ActivityIndicator size={'large'} />
          </View>}
      <Footer />
    </ScrollViewIndicator >
  )

}

export default Bets
