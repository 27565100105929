import TextWhite from "@/components/TextWhite";
import { AuthProvider } from "@/context/AuthProvider";
import Router from "@/Router";
import { StatusBar } from "expo-status-bar";
import { View, Text } from "react-native";
import { AppProvider } from "@/context/AppContext";
import AppNavigator from "@/navigators/AppNavigator";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { NativeRouter, Route, Link } from "react-router-native";
import { Provider } from 'react-redux';

import { notifyUser } from "@/context/AuthProvider";

import '@/util/i18next'
import store from "@/store";
import { useEffect, useState } from "react";

export default function App() {

  const [userNotify, setUserNotify] = useState<boolean>(false)

  return (

    <HelmetProvider >
      <SafeAreaProvider>
        <Provider store={store}>
          <AuthProvider>
            <AppProvider >
              <StatusBar style="light" />
              <Router />

            </AppProvider>
          </AuthProvider >
        </Provider>
      </SafeAreaProvider>
    </HelmetProvider>

  )
}
