import { useAppContext } from '@/context/AppContext'
import { Icons } from '@/theme/theme'
import { Screen } from '@/theme/types'
import { isLarge } from '@/util/isLarge'
import { useNavigation } from '@react-navigation/native'
import { Dimensions, Image, ImageBackground, Pressable, StyleSheet, TouchableOpacity, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import TextWhite from './TextWhite'
import { colors } from '@/theme/colors'
const WIDTH = Dimensions.get('window').width

const Footer = () => {

    const navigation = useNavigation()
    const { screens } = useAppContext()
    const { t } = useTranslation()

    return (
        screens &&
        <ImageBackground style={styles.image} source={require('@/assets/footer-bg.png')}>
            <View style={styles.container}>

                <View style={{ gap: 30 }}>
                    <TextWhite style={{ fontWeight: 'bold', marginBottom: 10 }}>{t('footer.links')}</TextWhite>
                    <Pressable
                        // onPress={() => navigation.reset({
                        //     index: 0,
                        //     routes: [{ name: 'Page', params: { title: 'terms-of-use' } }],

                        // })}
                        onPress={() => navigation.navigate({
                            name: 'Page',
                            params: { title: 'terms-of-use' },
                            merge: true,
                        })}
                    >
                        <TextWhite >{t('footer.terms')}</TextWhite>
                    </Pressable>
                </View>

                <View style={{ alignItems: 'center' }}>
                    <Image style={{ width: 145, height: 33 }} source={Icons.logo} />
                    <View style={{ marginTop: 40, gap: 10, flexDirection: 'row' }}>
                        <Image style={styles.social} source={Icons.facebook} />
                        <Image style={styles.social} source={Icons.instagram} />
                        <Image style={styles.social} source={Icons.youtube} />
                        <Image style={styles.social} source={Icons.twitter} />
                        <Image style={styles.social} source={Icons.linkedin} />
                    </View>
                    <View style={{ marginTop: isLarge ? 160 : 20, gap: 10, flexDirection: 'row' }}>
                        <Image style={styles.icon} source={Icons.visa} />
                        <Image style={styles.icon} source={Icons.amex} />
                        <Image style={styles.icon} source={Icons.mastercard} />
                        <Image style={styles.icon} source={Icons.discover} />
                        <Image style={styles.icon} source={Icons.paypal} />
                    </View>
                </View>

                {isLarge && <View style={{ gap: 30 }}>
                    <TextWhite style={{ fontWeight: 'bold', marginBottom: 10 }}>{t('footer.categories')}</TextWhite>
                    {screens && screens.map((screen: Screen) => (
                        <TouchableOpacity
                            key={screen?.id}
                            // onPress={() => navigation.reset({
                            //     index: 0,
                            //     routes: [{ name: 'Bets', params: { title: screen?.url.replace("/", "") } }],

                            // })}
                            onPress={() => navigation.navigate({
                                name: 'Bets',
                                params: { title: screen?.url.replace("/", "") },
                                merge: true,
                            })}
                        >
                            <TextWhite key={screen.id}>{screen.title}</TextWhite>
                        </TouchableOpacity>
                    ))}

                </View>}
            </View>

            {/*<TextWhite style={{ marginVertical: 20, textAlign: 'center' }}>Designed & Developed by Lioncode Web and Mobile Solutions</TextWhite>*/}

        </ImageBackground>
    )

}

const styles = StyleSheet.create({

    image: {
        width: '100%',
        minHeight: 520,
        paddingVertical: isLarge ? 40 : 0,
        justifyContent: 'flex-end',
        backgroundColor: colors.bg_main
    },
    social: {
        width: 30,
        height: 30
    },
    icon: {
        width: 31,
        height: 24
    },
    container: {

        width: '100%',
        borderBottomWidth: 1,
        borderTopWidth: 1,
        borderColor: 'white',
        padding: 20,
        paddingVertical: 60,
        gap: 60,
        flexDirection: isLarge ? 'row' : 'column',
        justifyContent: !isLarge ? 'center' : 'space-around',
        alignItems: 'center',
        flexShrink: 1,
    }

})

export default Footer